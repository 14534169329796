import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FaAngleDown, FaCheck } from "react-icons/fa";
import LocalizedLink from "components/localizedLink";
import useTranslations from "hooks/useTranslations";
import { rhythm, scale } from "utils/typography";
import { mq, localizedDate } from "utils/helper";
import Video from "components/mdx-components/video";
import { ThemeContext } from "context/theme-context";

function UnitDetails({ unit, locale }) {
  const tr = useTranslations();
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const {
    observation_id,
    date_of_incident: date,
    incident_id,
    location_ar,
    location_en,
    link: onlineLink,
    summary_ar,
    summary_en,
    title_en,
    title_ar,
    collection,
    collection_ar,
  } = unit;

  const renderIncidentLinks = () => {
    let incident_ids = [];
    if (incident_id.indexOf(",") > -1) {
      incident_ids = incident_id.split(",").map((s) => {
        return s.replace("\n", "");
      });
    } else {
      incident_ids = [incident_id];
    }
    return incident_ids.map((iid) => {
      return (
        <LocalizedLink
          key={iid}
          to={`data/incidents/${iid}`}
          css={{
            display: "block",
            fontWeight: 500,
            ":hover": {
              color: colors.primary,
            },
          }}
        >
          {iid}
        </LocalizedLink>
      );
    });
  };

  const link = `/assets/observations/${observation_id}.mp4`;
  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gridColumnGap: rhythm(3),
        backgroundColor: colors.light,
        border: `3px solid ${colors.dark}`,
        padding: `${rhythm(3)} ${rhythm(5)}`,
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          display: "block",
          padding: rhythm(1),
        },
      }}
    >
      <div>
        <h2
          css={{
            marginTop: 0,
            [mq[0]]: {
              ...scale(0.6),
            },
          }}
        >
          {isRTL ? title_ar : title_en}
        </h2>
        <small css={{ display: "block", opacity: 0.6 }}>
          <FaCheck />
          {tr("Verified Observation")}: {observation_id}
        </small>
        <small
          css={{
            backgroundColor: "#C20A0A",
            color: colors.light,
            fontWeight: "bold",
            padding: rhythm(0.2),
            paddingBottom: 0,
          }}
        >
          {tr("Warning: this video may contain graphic content")}
        </small>
        <Video
          src={link}
          css={{
            marginTop: rhythm(1),
            marginBottom: rhythm(1),
          }}
        />

        <a href={link}>
          {tr("Download file")} <FaAngleDown />
        </a>
        <dl
          css={{
            marginTop: rhythm(2),
          }}
        >
          <dt>{tr("Online Link")}</dt>
          <dd>
            <a href={onlineLink}>{tr("Link")}</a>
          </dd>
        </dl>
      </div>

      <div>
        <dl>
          <dt>{tr("Incidents")}:</dt>

          <dd>{renderIncidentLinks()}</dd>

          <dt>{tr("Summary")}:</dt>
          <dd
            css={{
              lineBreak: "strict",
              maxHeight: "300px",
              maxWidth: "300px",
              overflowY: "auto",
            }}
          >
            {isRTL ? summary_ar : summary_en}
          </dd>
          <dt>{tr("Date of occurency")}:</dt>
          <dd>{localizedDate(date, "y,M,d")}</dd>
          <dt>{tr("Location")}:</dt>
          <dd>{isRTL ? location_ar : location_en}</dd>
          <dt>{tr("Collections")}:</dt>
          <dd>{isRTL ? collection_ar : collection}</dd>
        </dl>
      </div>
    </div>
  );
}

UnitDetails.propTypes = {
  unit: PropTypes.object,
  locale: PropTypes.string,
};

export default UnitDetails;
