import React, { useContext } from "react";
import { css } from "@emotion/react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { navigate } from "gatsby";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import { rhythm } from "utils/typography";

Modal.setAppElement(`#___gatsby`);

function UnitModal({ location, isOpen, children, prevPath }) {
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => navigate(`${locale}/${prevPath}`)}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.50)",
          zIndex: 10,
        },
        content: {
          marginTop: "3rem",
          position: "absolute",
          border: "none",
          background: "none",
          padding: 0,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
        },
      }}
      contentLabel="Modal"
    >
      <div>
        <div
          css={{
            display: "flex",
            maxWidth: rhythm(47.25),
            margin: "auto",
            width: "100%",
            position: "relative",
          }}
        >
          {children}
          <MdClose
            data-testid="modal-close"
            onClick={() => navigate(`/${locale}/${prevPath}`)}
            css={{
              cursor: "pointer",
              color: colors.dark,
              fontSize: "30px",
              position: "absolute",
              top: rhythm(0.6),
              right: rhythm(0.6),
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UnitModal;
