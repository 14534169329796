import React from "react";
import Layout from "components/layout";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3";
import UnitModal from "components/modal";

let windowWidth;

function UnitLayout({ location, isModal = false, children }) {
  const prevPath = location.state ? location.state.from : "/";
  if (!windowWidth && typeof window !== `undefined`) {
    windowWidth = window.innerWidth;
  }
  if (isModal && windowWidth > 750) {
    isModal = true;
  }
  if (isModal && UnitModal) {
    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
          <div>
            {modal ? (
              <UnitModal isOpen={true} location={location} prevPath={prevPath}>
                {children}
              </UnitModal>
            ) : (
              <div
                css={{
                  background: `rgba(0,0,0,0.03)`,
                  minHeight: `100vh`,
                }}
              ></div>
            )}
          </div>
        )}
      </ModalRoutingContext.Consumer>
    );
  }

  return <Layout>{children}</Layout>;
}

export default UnitLayout;
