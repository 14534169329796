import React, { useContext } from "react";
import { graphql } from "gatsby";
import UnitDetails from "components/database/unit-details";
import UnitLayout from "components/unit-layout";
import { LocaleContext } from "context/locale-context";

function UnitTemplate({ pageContext, data, location }) {
  const locale = useContext(LocaleContext);
  let isModal = false;
  // We don't want to show the modal if a user navigates
  // directly to a unit so if this code is running on Gatsby's
  // initial render then we don't show the modal, otherwise we
  // do.
  if (
    typeof window !== "undefined" &&
    window.___MNEMONIC_INITIAL_RENDER_COMPLETE
  ) {
    isModal = true;
  }

  return (
    <UnitLayout location={location} isModal={isModal}>
      <UnitDetails unit={data.unitsJson} locale={locale} />
    </UnitLayout>
  );
}

export default UnitTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    unitsJson(id: { eq: $id }) {
      observation_id
      incident_id
      latitude
      link
      location_en
      location_ar
      longitude
      title_en
      title_ar
      verified
      date_of_incident
      collection
      collection_ar
      summary_en
      summary_ar
    }
  }
`;
